<template>
  <default-layout>
    <div v-if="dataFetched">
      <client-only
        ><component
          :is="paymentName.replace('_', '-')"
          :paymentMethodName="paymentMethodName"
          :virtualAccountNumber="virtualAccountNumber"
          :paymentCode="paymentCode"
          :viewMybookingUrl="viewMybookingUrl"
          :orderNumber="orderNumber"
          :actions="actions"
          :price="price"
          :paymentDueDiff="paymentDueDiff"
          :orderUuid="$route.params.uuid"
        ></component
      ></client-only>
    </div>
    <cc-payment-modal />
    <script
      id="midtrans-script"
      src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
      :data-environment="midtransMode"
      :data-client-key="midtransClientKey"
      type="text/javascript"
    ></script>
  </default-layout>
</template>

<script>
import { mapState } from 'vuex';
const PermataVa = () => import('@/components/how-to-pay/permata');
const BcaVa = () => import('@/components/how-to-pay/bca');
const BniVa = () => import('@/components/how-to-pay/bni');
const BriVa = () => import('@/components/how-to-pay/bri');
const AlfaGroup = () => import('@/components/how-to-pay/alfa');
const Indomaret = () => import('@/components/how-to-pay/indomaret');
const CreditCard = () => import('@/components/how-to-pay/credit-card');
const Gopay = () => import('@/components/how-to-pay/gopay');
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import CcPaymentModal from '@/components/checkout/modals/cc_payment_modal';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  middleware: ['auth', 'nonAdminProjectPartner'],
  async created() {
    try {
      this.$store.commit('cardPayment/SET_MIDTRANS_MODE', process.env.VUE_APP_MIDTRANS_MODE);

      console.log('PARAMSNYA NIHCCC 1: ', this.$route.params);
      const { data } = await this.$http.get('/api/payments/how_to_pay', {
        params: {
          uuid: this.$route.params.uuid,
        },
      });
      console.log('responsee', data);
      this.paymentMethodName = data.data.payment_method_title;
      this.virtualAccountNumber = data.data.va_number;
      this.paymentCode = data.data.payment_code;
      this.viewMybookingUrl = `/order?q=${data.data.order_number}`;
      this.paymentName = data.data.payment_method;
      this.actions = data.data.actions;
      this.price = data.data.total_net_price;
      this.orderNumber = data.data.order_number;
      console.log('OAKSODKASD,', this.orderNumber);
      this.paymentDue = data.data.payment_due;
      this.paymentDueDiff = new Date(this.paymentDue) - new Date();
      this.dataFetched = true;
    } catch (e) {
      console.log('ERROR HOW TO PAY: ', e);
    }
  },
  data() {
    return {
      paymentDue: null,
      paymentDueDiff: null,
      paymentMethodName: null,
      virtualAccountNumber: null,
      paymentCode: null,
      viewMybookingUrl: null,
      paymentName: '',
      actions: null,
      price: null,
      orderNumber: null,
      dataFetched: false,
      breakLoop: false,
    };
  },
  components: {
    PermataVa,
    BniVa,
    BriVa,
    Gopay,
    BcaVa,
    AlfaGroup,
    Indomaret,
    CreditCard,
    DefaultLayout,
    CcPaymentModal,
  },
  computed: {
    ...mapState({
      bookingOrder: (state) => state.v2.booking.bookingOrder,
      midtransMode: (state) => state.cardPayment.midtransMode,
    }),

    midtransClientKey() {
      return process.env.VUE_APP_MIDTRANS_CLIENT_KEY;
    },
  },
  async mounted() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this.breakLoop) {
        break;
      }
      const result = await this.checkValid();
      if (!result) {
        this.breakLoop = true;
        this.$router.replace(this.viewMybookingUrl);
      }
    }
  },
  methods: {
    async checkValid() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 4000));

        let uuid = this.$route.params.uuid;
        if (uuid) {
          const { data } = await this.$http.get('/api/payments/how_to_pay/is_active', {
            params: {
              uuid: this.$route.params.uuid,
            },
          });

          return data.data;
        }

        return false;
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
  beforeDestroy() {
    this.breakLoop = true;
  },
};
</script>
